import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeTab from "./HomeTab";
import TaskEmptyScreen from "../myTask/TaskEmptyScreen";
import { TaskExtractModel } from "../myTask/useExtractMyTask";
import SideBarPanel from "../myTask/SideBarPanel";
import { formatDisplayDate } from "../../utils/date";
import { CustomSelect } from "../myTask/UserStateChange";
import { useUpdateTask } from "../../queries/admin";
import { queryClient } from "../../queries/client";
import { useAuthStore } from "../../store/useAuthStore";
import { limitString } from "../../utils/string";
import OverDue from "../calender/OverDue";
import Tooltip from "../../shared/Tooltip";
import { AnalyticsConstant } from "../../AnalyticsContant";
import { handleClickForTracking } from "../../analytics";

const HomePageAssignedTask = ({ task }: { task: TaskExtractModel[] }) => {
  const [status, setStatus] = useState("Pending");
  const [isUpdateProjectDetails, setUpdateProjectDetails] = useState<{
    state: boolean;
    task?: TaskExtractModel;
  }>({
    state: false,
    task: undefined,
  });
  const [tagsByProject, setTagsByProject] = useState<Map<string, string[]>>(
    new Map()
  );

  const { mutate: updateTask } = useUpdateTask();

  useEffect(() => {
    const tagMap = new Map<string, Set<string>>();

    (task ?? []).forEach((ele: any) => {
      const projectId = ele.projectId ?? "default";
      if (!tagMap.has(projectId)) {
        tagMap.set(projectId, new Set());
      }
      (ele?.tags ?? []).forEach((tag: string) =>
        tagMap.get(projectId)!.add(tag)
      );
    });

    const finalTagMap = new Map<string, string[]>();
    tagMap.forEach((tagSet, projectId) => {
      finalTagMap.set(projectId, Array.from(tagSet));
    });

    setTagsByProject(finalTagMap);
  }, [task]);
  const { role } = useAuthStore();
  return (
    <div className="px-4 sm:px-6 flex-1 lg:px-8  bg-white pt-6 border border-borderColor shadow-box rounded-xl">
      {isUpdateProjectDetails.state && isUpdateProjectDetails?.task && (
        <SideBarPanel
          tags={tagsByProject.get(`${isUpdateProjectDetails?.task?.projectId}`)}
          realTasks={isUpdateProjectDetails.task}
          open={isUpdateProjectDetails.state ?? false}
          onClose={() => {
            setUpdateProjectDetails({ state: false, task: undefined });
          }}
          isAdmin={role === "ADMIN"}
        />
      )}
      <div className="sm:flex sm:items-center pb-2 ">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Managed by me
          </h1>

          <p className="mt-1 text-sm text-gray-700">
            {task.filter(
              (ele: any) =>
                ((ele.status === "CREATED" || ele.status === "INPROGRESS") &&
                  status === "Pending") ||
                (ele.status === "COMPLETED" && status === "Completed")
            ).length > 0
              ? `${
                  task.filter(
                    (ele: any) =>
                      ((ele.status === "CREATED" ||
                        ele.status === "INPROGRESS") &&
                        status === "Pending") ||
                      (ele.status === "COMPLETED" && status === "Completed")
                  ).length
                } Tasks`
              : `0 Task`}
          </p>
        </div>
      </div>
      {/* <HomeTab
        type={status}
        tabs={["Pending", "Completed"]}
        setType={setStatus}
      /> */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto max-h-[500px] min-h-[500px] sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle">
            {task.length === 0 ? (
              <TaskEmptyScreen text={"Managed by you"} />
            ) : (
              <table className="divide-y divide-gray-300 w-full rounded-xl sm:mx-2">
                <thead className="sticky z-30 top-0 bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      Task Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900"
                    >
                      Due date
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white ">
                  {task
                    // .filter(
                    //   (ele: any) =>
                    //     ((ele.status === "CREATED" ||
                    //       ele.status === "INPROGRESS") &&
                    //       status === "Pending") ||
                    //     (ele.status === "COMPLETED" && status === "Completed")
                    // )
                    .filter(
                      (ele: any) =>
                        !ele.closedStates.find(
                          (data: any) => data.name === ele.status
                        )
                    )
                    .map((taskDetails: TaskExtractModel) => (
                      <tr
                        className="cursor-pointer rounded-2xl"
                        key={taskDetails.id}
                      >
                        <td
                          onClick={() => {
                            handleClickForTracking(
                              AnalyticsConstant.home_open_managed_task
                            );
                            setUpdateProjectDetails({
                              task: taskDetails,
                              state: true,
                            });
                          }}
                          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                        >
                          {taskDetails.name.length > 20 ? (
                            <Tooltip text={taskDetails.name ?? ""}>
                              <p>{limitString(taskDetails.name, 20)}</p>
                            </Tooltip>
                          ) : (
                            <p>{limitString(taskDetails.name, 20)}</p>
                          )}

                          <p className="text-xs text-gray-400">
                            {limitString(taskDetails.projectName, 20)}
                          </p>
                        </td>

                        <td className="flex flex-col whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <p>
                            {formatDisplayDate(taskDetails.endDate)
                              .split(" ")
                              .slice(0, 2)
                              .join(" ")}
                          </p>
                          <OverDue task={taskDetails} isShort />
                        </td>
                        <td className="relative whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <CustomSelect
                            isPreview={true}
                            state={taskDetails.states ?? []}
                            data={taskDetails.status}
                            onChange={(data: any) => {
                              updateTask(
                                {
                                  projectId: taskDetails.projectId ?? "",
                                  taskId: taskDetails.id ?? "",
                                  status: data.id,
                                },
                                {
                                  onSuccess: () => {
                                    queryClient.invalidateQueries("overview");
                                  },
                                }
                              );
                            }}
                          />
                        </td>

                        <td className="whitespace-nowrap absolute z-30 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8"></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageAssignedTask;
