import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { CustomSelect } from "./UserStateChange";
import {
  MessageSquare,
  Paperclip,
  Activity,
  Calendar,
  User,
  Clock,
  Send,
  Plus,
  Flag,
  Tag,
  Users,
  UserPlus,
  Bell,
  Check,
  X,
  Edit,
  Trash,
  Download,
  Building2,
} from "lucide-react";
import {
  useCreateComment,
  useGetAllComment,
  useUpdateComment,
} from "../../queries/comment";
import { useAuthStore } from "../../store/useAuthStore";
import _ from "lodash";
import { extractDateTimeFromISO } from "../../utils/string";
import { queryClient } from "../../queries/client";
import { HStack } from "../../shared/utils";
import OptionMenus from "../project/OptionMenus";
import { deleteComment } from "../../api/comment";
import {
  useGetAllTaskHistory,
  useGetTask,
  useUpdateTask,
  useUpdateTaskRead,
} from "../../queries/admin";
import Tooltip from "../../shared/Tooltip";
import {
  useCreateUserTaskRole,
  useDeleteUserTaskRole,
  useGetUserProjectRoles,
  useGetUserTaskRoles,
} from "../../queries/userRole";
import SearchAutoCompleteDropDown, {
  SwitchDropDownModel,
} from "./SearchInputBox";
import { UserProjectRoles } from "../../api/userRole";
import { CommentModel } from "../../types/TaskModel";
import { formatDisplayDate } from "../../utils/date";
import SearchStringAutoCompleteDropDown from "./SearchStringInputBox";
import {
  useCreateTaskDocument,
  useDeleteTaskDocument,
  useGetPresignedURLforTaskDocument,
  useGetProjectDocuments,
  useGetTaskDocuments,
} from "../../queries/document";
import axios from "axios";
import {
  createTaskDocument,
  generatePresignedUrlForTask,
  TaskDocument,
} from "../../api/document";
import { compareHistory, handleS3Url } from "./taskUtils";
import CommentEmptyScreen from "./CommentEmptyScreen";
import DocumentEmptyScreen from "./DocumentEmptyScreen";
import { FileItem } from "../project/AttachmentsTab";
import DeleteTaskModal from "../../modal/DeleteTask";
import MailReminderModal from "../../modal/MailReminder";
import ActivitiesSection from "../aggrid/Activity";
import UploadLinkModal from "../../modal/UploadLink";
import UpdateSubTaskModal from "../../modal/UpdateSubTask";
import { RecurringConfig } from "./SideBarAddTaskPanel";
import { Input } from "../../shared/InputField";
import CustomDownloadDialog from "../project/CustomDialogBox";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface Comment {
  author: string;
  text: string;
  date: string;
}

export interface File {
  name: string;
  size: string;
}

export interface ActivityItem {
  description: string;
  date: string;
}

export function sortByUpdatedAt(
  array: any[],
  ascending: boolean = true
): any[] {
  return array.sort((a, b) => {
    const dateA = new Date(a.updatedAt);
    const dateB = new Date(b.updatedAt);
    return ascending
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });
}

export interface EditableFields {
  name: boolean;
  recurring: boolean;
  description: boolean;
  groupName: boolean;
  status: boolean;
  startDate: boolean;
  endDate: boolean;
  creator: boolean;
  assignedTo: boolean;
  priority: boolean;
  tags: boolean;
}

export interface TabButtonProps {
  value: string;
  icon: React.ElementType;
  label: string;
  activeTab: string;
  onClick: (value: string) => void;
  onClose: () => {};
}

export const TabButton: React.FC<TabButtonProps> = ({
  value,
  icon: Icon,
  label,
  activeTab,
  onClick,
  onClose,
}) => (
  <button
    onClick={() => onClick(value)}
    className={`flex items-center justify-center py-3 px-4 flex-grow transition-all duration-300 text-sm ${
      activeTab === value
        ? "bg-white text-blue-600 font-semibold shadow-inner"
        : "text-gray-600 hover:text-blue-600 hover:bg-white/50"
    }`}
  >
    <Icon
      className={`w-5 h-5 mr-2 transition-transform duration-300 ${
        activeTab === value ? "scale-110" : ""
      }`}
    />
    {label}
  </button>
);
export interface TaskSidebarProps {
  task?: TaskModel;
  realTasks?: any;
  isAdmin: boolean;
  open: boolean;
  onClose: any;
  tags?: string[];
}

export interface TaskReadModel {
  taskId: string;
}

export interface RecurrenceData {
  nextInstanceStartDate: string;
}

export interface TaskModel {
  comments?: CommentModel[];
  groupName?: string;
  companyId?: string;
  companyName?: string;
  recurrenceData?: RecurrenceData;
  parentTaskId?: string;
  isRecurring?: boolean;
  recurringConfig?: RecurringConfig[];
  updateSubTasks?: boolean;
  createdAt?: string;
  taskId?: string;
  creatorId?: string;
  description?: string;
  documents?: any[];
  endDate?: string;
  id?: string;
  isDeleted?: boolean;
  name?: string;
  priority?: string;
  projectId?: string;
  projectSpecificStatusId?: string;
  startDate?: string;
  status?: string;
  tags?: string[];
  updatedAt?: string;
}

const SideBarPanel: React.FC<TaskSidebarProps> = ({
  realTasks,
  tags,
  open,
  onClose,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const { data: realTask } = useGetTask(realTasks.id ?? "");
  const { data: history } = useGetAllTaskHistory(realTasks.id ?? "");
  const nextRecurringDate =
    realTask?.recurrenceData?.nextInstanceStartDate ?? "";
  const { id } = useAuthStore();
  const [isAdmin, setIsAdmin] = useState(false);

  const { mutate: updateTask } = useUpdateTask();
  const { mutate: addComment } = useCreateComment();
  const { mutate: updateTheComment } = useUpdateComment();
  const { mutate: updateTaskRead } = useUpdateTaskRead();
  useEffect(() => {
    updateTaskRead({ taskId: realTask?.id ?? "" });
  }, [realTask]);
  const { data: comments } = useGetAllComment(realTask?.id ?? "");
  const authStore = useAuthStore();

  const [intervalMonth, setIntervalMonth] = useState(3);
  const [recurringEndDate, setRecurringEndDate] = useState(30);
  const [recurringLastDate, setRecurringLastDate] = useState("");
  const [recurringStartDate, setRecurringStartDate] = useState(20);

  const [activeTab, setActiveTab] = useState<string>("comments");
  const [newComment, setNewComment] = useState<string>("");
  const [updateComment, setUpdateComment] = useState<string>("");
  const [activityFilter, setActivityFilter] = useState<string>("all");
  const [editMode, setEditMode] = useState<EditableFields>({
    name: false,
    description: false,
    recurring: false,
    groupName: false,
    status: false,
    startDate: false,
    endDate: false,
    creator: false,
    assignedTo: false,
    priority: false,
    tags: false,
  });
  const [editedTask, setEditedTask] = useState<TaskModel>({ ...realTask });
  useEffect(() => {
    if (realTask) {
      setEditedTask(realTask);

      if (
        realTask.isRecurring &&
        realTask?.recurringConfig &&
        realTask?.recurringConfig?.length > 0
      ) {
        setIntervalMonth(realTask.recurringConfig[0].recurrenceInterval);
        setRecurringEndDate(realTask.recurringConfig[0].dueDate);
        setRecurringLastDate(realTask.recurringConfig[0].recurrenceEndDate);
        setRecurringStartDate(realTask.recurringConfig[0].startDateOffset);
      }
    }
  }, [realTask]);
  const [newTag, setNewTag] = useState<string>("");
  const [newAssignee, setNewAssignee] = useState<string>("");

  const handleEdit = (field: keyof EditableFields) => {
    handleClickForTracking(AnalyticsConstant.sidebar_edit_feild + field);
    if (isAdmin || isAdminAccess || field === "assignedTo") {
      setEditMode({ ...editMode, [field]: true });
    }
  };
  const [updateSubTasks, setUpdateSubTasks] = useState(false);

  const handleSave = (field: keyof EditableFields, value?: string) => {
    handleClickForTracking(AnalyticsConstant.sidebar_save_feild + field);
    if (realTask) {
      if (field === "creator") {
        createManager({
          id: "",
          name: "",
          type: managerEmail,
        });
      } else if (field === "name") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                name: editedTask.name,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            name: editedTask.name,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "description") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                description: editedTask.description,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            description: editedTask.description,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "groupName") {
        updateTask({
          projectId: realTasks.projectId ?? "",
          taskId: realTask.id ?? "",
          groupName: editedTask.groupName,
          updateSubTasks: true,
        });
      } else if (field === "startDate" && editedTask.startDate) {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                startDate: editedTask.startDate
                  ? new Date(editedTask.startDate).toISOString()
                  : "",
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            startDate: new Date(editedTask.startDate).toISOString(),
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "endDate" && editedTask.endDate) {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                endDate: editedTask.endDate
                  ? new Date(editedTask.endDate).toISOString()
                  : "",
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            endDate: new Date(editedTask.endDate).toISOString(),
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "status") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                status: value,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            status: value,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "priority") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                priority: value,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            priority: value,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "tags") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",

                tags: value
                  ? [...(realTask?.tags ?? []), value]
                  : [...(realTask?.tags ?? []), newTag],
                updateSubTasks: update,
              });
              setNewTag("");
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            updateSubTasks: updateSubTasks,
            tags: value
              ? [...(realTask?.tags ?? []), value]
              : [...(realTask?.tags ?? []), newTag],
          });
          setNewTag("");
        }
      }
      setEditMode({ ...editMode, [field]: false });

      Object.assign(realTask, editedTask);
    }
  };

  const handleRecurringSave = () => {
    if (realTask) {
      if (realTask.groupName && !realTask.parentTaskId) {
        setModelOpen({
          state: true,
          type: "Update SubTask",
          data: realTask,
          onComplete: (update: boolean) => {
            updateTask({
              projectId: realTasks.projectId ?? "",
              taskId: realTask.id ?? "",
              recurringConfig: [
                {
                  recurrenceInterval: intervalMonth,
                  startDateOffset: recurringStartDate,
                  dueDate: recurringEndDate,
                  recurrenceEndDate: recurringLastDate
                    ? new Date(recurringLastDate).toISOString()
                    : "",
                  createImmediately: false,
                },
              ],
              updateSubTasks: update,
            });
            setNewTag("");
          },
        });
      } else {
        updateTask({
          projectId: realTasks.projectId ?? "",
          taskId: realTask.id ?? "",
          updateSubTasks: updateSubTasks,
          recurringConfig: [
            {
              recurrenceInterval: intervalMonth,
              startDateOffset: recurringStartDate,
              dueDate: recurringEndDate,
              recurrenceEndDate: recurringLastDate
                ? new Date(recurringLastDate).toISOString()
                : "",
              createImmediately: false,
            },
          ],
        });
        setNewTag("");
      }
      setEditMode({ ...editMode, recurring: false });

      Object.assign(realTask, editedTask);
    }
  };

  const handleCancel = (field: keyof EditableFields) => {
    handleClickForTracking(AnalyticsConstant.sidebar_cancel_feild);
    setEditMode({ ...editMode, [field]: false });
    setEditedTask({ ...realTask });
  };

  const handleInputChange = (field: keyof TaskModel, value: any) => {
    setEditedTask({ ...editedTask, [field]: value });
  };

  const handleAddComment = () => {
    handleClickForTracking(AnalyticsConstant.sidebar_comment_added);
    if (newComment.trim()) {
      updateComment
        ? updateTheComment({
            commentId: updateComment,
            userId: authStore.id ?? "",
            content: newComment,
            taskId: realTasks.id,
          })
        : addComment({
            userId: authStore.id ?? "",
            content: newComment,
            taskId: realTasks.id,
          });

      setNewComment("");
      setUpdateComment("");
    }
  };

  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          handleClickForTracking(AnalyticsConstant.sidebar_add_attachments);
          const presignedUrl = await generatePresignedUrlForTask({
            taskId: realTasks.id,
            projectId: realTasks.projectId,
            fileName: file.name,
            size: file.size,
            isProject: false,
            isUpload: true,
          });

          const response = await axios.put(presignedUrl.data, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          if (response.status === 200) {
            await createTaskDocument({
              projectId: realTasks.projectId,
              taskId: realTasks.id,
              type: "DOCUMENT",
              tags: realTask?.tags,
              size: file.size,
              name: file.name,
              location: file.name,
            });

            toast(`${file.name} uploaded successfully`, {
              type: "success",
              autoClose: 2000,
            });
          } else {
            throw new Error(`Failed to upload ${file.name}`);
          }
        } catch (error) {
          toast(`Error uploading ${file.name}`, {
            type: "error",
            autoClose: 2000,
          });
        }
      }
      queryClient.invalidateQueries("get-task-documents");
      queryClient.invalidateQueries("getAllTask");
      queryClient.refetchQueries("getAllTask");
    }
  };

  const handleSendReminder = () => {
    handleClickForTracking(AnalyticsConstant.sidebar_open_reminder);
    setModelOpen({
      type: "Reminder",
      data: realTask,
      state: true,
    });
  };
  const handleTaskDelete = () => {
    handleClickForTracking(AnalyticsConstant.sidebar_open_delete);
    setModelOpen({
      type: "Delete",
      data: realTask,
      state: true,
    });
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const handleSelectFile = (file: any) => {
    setSelectedFiles((prev) => {
      const isSelected = prev.some((f) => f.id === file.id);
      if (isSelected) {
        return prev.filter((f) => f.id !== file.id);
      } else {
        return [...prev, file];
      }
    });

    const selected = [...selectedFiles, file];

    if (
      projectDocuments.find((docs: any) => {
        return selected.find((files) => files.location === docs.location);
      }) &&
      taskDocuments.find((docs: any) => {
        return selected.find((files) => files.location === docs.location);
      })
    ) {
      setSelectedFiles([]);
      toast(
        "Multiselecting between project and task document will be implemented soon",
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
  };

  const priorityColor = {
    NORMAL: "bg-gray-100 text-gray-800",
    LOW: "bg-green-100 text-green-800",
    MEDIUM: "bg-yellow-100 text-yellow-800",
    HIGH: "bg-red-100 text-red-800",
    CRITICAL: "bg-red-100 text-red-800",
  };

  const { data: userProjectRoles } = useGetUserProjectRoles(
    realTasks.projectId
  );
  const { data: userTaskRoles } = useGetUserTaskRoles(realTasks.id);
  const [manager, setManager] = useState<UserProjectRoles[]>([]);
  const [assignee, setAssignee] = useState<UserProjectRoles[]>([]);
  const { mutate: assignTask } = useCreateUserTaskRole();
  const { mutate: deleteUserFromTask } = useDeleteUserTaskRole();
  useEffect(() => {
    if (userTaskRoles && userTaskRoles.length > 0) {
      const assigner = userTaskRoles?.find((ele) => ele.role === "ASSIGNER");
      const assignee = userTaskRoles?.find((ele) => ele.role === "ASSIGNEE");

      const assignerList =
        userTaskRoles?.filter((ele) => ele.role === "ASSIGNER") ?? [];
      const assigneeList =
        userTaskRoles?.filter((ele) => ele.role === "ASSIGNEE") ?? [];
      setAssignee(assigneeList);
      setManager(assignerList);
      if (assigner) {
        setSelectedTransaction({
          id: assigner?.id ?? "",
          type: assigner?.email ?? "",
          name: assigner?.name ?? "",
        });
      }
    }
  }, [userTaskRoles]);
  const [modalOpen, setModelOpen] = useState<{
    state: boolean;
    type: "Delete" | "Reminder" | "Link" | "Update SubTask";
    data: any;
    onComplete?: any;
  }>({
    type: "Delete",
    state: false,
    data: undefined,
    onComplete: () => {},
  });

  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });
  const [selectedAssignee, setSelectedAssignee] = useState<SwitchDropDownModel>(
    { name: "", id: "", type: "" }
  );

  function createManager(value?: SwitchDropDownModel) {
    if (value?.type) {
      assignTask(
        {
          projectId: realTasks.projectId,
          taskId: realTasks.id,
          email: value?.type ?? "",
          userId: value.id ?? "",
          role: "ASSIGNER",
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries("getUserTaskRoles");
            setEditMode({ ...editMode, ["creator"]: false });
          },
        }
      );
    }
  }

  function createAssignee(value?: SwitchDropDownModel) {
    if (value?.type) {
      assignTask(
        {
          projectId: realTasks.projectId,
          taskId: realTasks.id,
          email: value?.type ?? "",
          role: "ASSIGNEE",
          userId: value.id,
          sendNotification: sendNotification,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries("getAllTask");
            queryClient.refetchQueries("getAllTask");
            queryClient.invalidateQueries("overview");
            queryClient.refetchQueries("overview");
            toast(`${value.name} added to the task`, {
              type: "success",
              autoClose: 2000,
            });
            queryClient.invalidateQueries("getUserTaskRoles");
            setEditMode({ ...editMode, ["creator"]: false });
          },
        }
      );
    }
  }

  const [managerEmail, setManagerEmail] = useState("");
  const [showAssignee, setShowAssignee] = useState(false);

  const { data: taskDocuments } = useGetTaskDocuments(realTasks.id);
  const { data: projectDocuments } = useGetProjectDocuments(
    realTasks.projectId
  );
  const { mutate: generateS3UrlForTask } = useGetPresignedURLforTaskDocument();
  const { mutate: uploadTaskDocument } = useCreateTaskDocument();

  const { mutate: deleteDocument } = useDeleteTaskDocument();

  const handleDownload = (fileName: any) => {
    handleClickForTracking(AnalyticsConstant.sidebar_download_attachment);
    generateS3UrlForTask(
      {
        taskId: realTasks.id,
        projectId: realTasks.projectId,
        fileName: fileName.name,
        isProject: false,
        isUpload: false,
      },
      {
        onSuccess: async (data) => {
          const presignedUrl = data.data;
          handleS3Url(presignedUrl, fileName);
        },
        onError: (data) => {},
      }
    );
  };

  const [sendNotification, setSendNotification] = useState(true);

  const handleDelete = (documentId: string, taskId: string) => {
    deleteDocument({
      documentId: documentId,
      taskId: taskId,
    });
  };
  const view = () => {};

  const [isAdminViewer, setIsAdminViewer] = useState(false);

  useEffect(() => {
    const user = userProjectRoles?.find(
      (data) => data.role === "VIEWER" && data.userId === id
    );
    if (user) {
      setIsAdminViewer(true);
    } else {
      setIsAdminViewer(false);
    }
  }, [userProjectRoles]);

  const [isAdminAccess, setIsAdminAccess] = useState(false);

  useEffect(() => {
    const user = userProjectRoles?.find(
      (data) => data.role === "ADMIN" && data.userId === id
    );
    if (user) {
      setIsAdminAccess(true);
    } else {
      setIsAdminAccess(false);
    }
  }, [userProjectRoles]);

  useEffect(() => {
    const user = userTaskRoles?.find(
      (data) => data.role === "ASSIGNER" && data.userId === id
    );
    if (user) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userTaskRoles]);

  const [showProjectFile, setShowProjectFile] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-128">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => {
                          handleClickForTracking(
                            AnalyticsConstant.sidebar_close_icon
                          );
                          onClose();
                        }}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <Icon
                          icon="octicon:x-16"
                          className="text-white h-5 cursor-pointer"
                          height={20}
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {modalOpen?.data && modalOpen?.type === "Delete" && (
                    <DeleteTaskModal
                      taskIdData={modalOpen?.data?.id}
                      projectId={modalOpen.data.projectId}
                      taskNameData={modalOpen?.data.name}
                      isOpen={modalOpen.state}
                      onClose={() => {
                        setModelOpen({
                          state: false,
                          type: "Delete",
                          data: null,
                        });

                        onClose();
                      }}
                    />
                  )}
                  {modalOpen?.data && modalOpen?.type === "Update SubTask" && (
                    <UpdateSubTaskModal
                      taskIdData={modalOpen?.data?.id}
                      taskData={modalOpen?.data}
                      isOpen={modalOpen.state}
                      onClose={() => {
                        setModelOpen({
                          state: false,
                          type: "Delete",
                          data: null,
                        });
                        onClose();
                      }}
                      onComplete={modalOpen.onComplete}
                    />
                  )}
                  {modalOpen?.data && modalOpen?.type === "Reminder" && (
                    <MailReminderModal
                      taskIdData={modalOpen?.data?.id}
                      projectId={modalOpen.data.projectId}
                      taskNameData={modalOpen?.data.name}
                      isOpen={modalOpen.state}
                      onClose={() => {
                        setModelOpen({
                          state: false,
                          type: "Reminder",
                          data: null,
                        });
                      }}
                    />
                  )}

                  {modalOpen?.data && modalOpen?.type === "Link" && (
                    <UploadLinkModal
                      taskIdData={modalOpen?.data?.taskId}
                      projectId={modalOpen.data.id}
                      isOpen={modalOpen.state}
                      onClose={() => {
                        setModelOpen({
                          state: false,
                          type: "Link",
                          data: null,
                        });
                      }}
                    />
                  )}

                  <div className="w-full h-full overflow-y-auto bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col shadow-xl rounded-lg overflow-hidden">
                    <div className="bg-white p-6 shadow-md">
                      <HStack className="flex flex-row justify-between">
                        {editMode.name ? (
                          <div className="mb-4 flex flex-row">
                            <input
                              type="text"
                              value={editedTask.name}
                              onChange={(e) =>
                                handleInputChange("name", e.target.value)
                              }
                              className="text-2xl font-bold w-full border px-2 rounded-lg border-gray-300 focus:border-blue-500 focus:outline-none"
                            />
                            <div className="flex justify-end mt-2 ml-2">
                              <Check
                                className="w-6 h-6 text-green-500 cursor-pointer mr-2"
                                onClick={() => handleSave("name")}
                              />
                              <X
                                className="w-6 h-6 text-red-500 cursor-pointer"
                                onClick={() => handleCancel("name")}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col items-start">
                            <h2
                              onClick={() =>
                                isAdmin || isAdminAccess
                                  ? handleEdit("name")
                                  : {}
                              }
                              className="text-2xl hover:bg-gray-100 rounded-md font-bold mb-4 text-gray-800 flex items-center"
                            >
                              {editedTask.name}
                            </h2>
                          </div>
                        )}
                        {(isAdmin || isAdminAccess) && (
                          <HStack className="gap-4">
                            <Tooltip
                              text={"Delete Task"}
                              _className="whitespace-nowrap cursor-pointer"
                              position="top-8"
                            >
                              <div
                                onClick={handleTaskDelete}
                                className=" bg-blue-50 text-blue-600 cursor-pointer hover:text-white px-2 py-2 rounded-full hover:bg-red-600 transition-colors duration-300 flex items-center justify-center text-sm font-medium"
                              >
                                <Trash className="w-4 h-4" />
                              </div>
                            </Tooltip>
                            <Tooltip
                              text={"Send Reminder"}
                              _className="whitespace-nowrap cursor-pointer -ml-16"
                              position="top-8"
                            >
                              <div
                                onClick={handleSendReminder}
                                className=" bg-blue-50 text-blue-600 cursor-pointer hover:text-white px-2 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center text-sm font-medium"
                              >
                                <Bell className="w-4 h-4" />
                              </div>
                            </Tooltip>
                          </HStack>
                        )}
                      </HStack>
                      {editMode.description ? (
                        <div className="mb-6">
                          <LabelText text={"Description"} />
                          <textarea
                            value={editedTask.description}
                            onChange={(e) =>
                              handleInputChange("description", e.target.value)
                            }
                            className="w-full text-sm text-gray-600 border rounded-md p-2 focus:border-blue-500 focus:outline-none"
                            rows={4}
                          />
                          <div className="flex justify-end mt-2">
                            <Check
                              className="w-5 h-5 text-green-500 cursor-pointer mr-2"
                              onClick={() => handleSave("description")}
                            />
                            <X
                              className="w-5 h-5 text-red-500 cursor-pointer"
                              onClick={() => handleCancel("description")}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            isAdmin || isAdminAccess
                              ? handleEdit("description")
                              : {}
                          }
                          className="flex flex-col items-start mb-6"
                        >
                          <LabelText text={"Description"} />
                          <p className="text-sm text-gray-600 hover:bg-gray-100 leading-relaxed flex-grow">
                            {editedTask.description}
                          </p>
                        </div>
                      )}
                      {realTask?.groupName && !realTask?.parentTaskId && (
                        <>
                          {editMode.groupName ? (
                            <div className="mb-6">
                              <LabelText text={"Group Name"} />
                              <input
                                type="text"
                                value={editedTask.groupName}
                                onChange={(e) =>
                                  handleInputChange("groupName", e.target.value)
                                }
                                className="w-full text-sm text-gray-600 border rounded-md p-2 focus:border-blue-500 focus:outline-none"
                              />

                              <div className="flex justify-end mt-2">
                                <Check
                                  className="w-5 h-5 text-green-500 cursor-pointer mr-2"
                                  onClick={() => handleSave("groupName")}
                                />
                                <X
                                  className="w-5 h-5 text-red-500 cursor-pointer"
                                  onClick={() => handleCancel("groupName")}
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                isAdmin || isAdminAccess
                                  ? handleEdit("groupName")
                                  : {}
                              }
                              className="flex flex-col items-start mb-6"
                            >
                              <LabelText text={"Group Name"} />
                              <p className="text-sm text-gray-600 hover:bg-gray-100 leading-relaxed flex-grow">
                                {editedTask.groupName}
                              </p>
                            </div>
                          )}
                        </>
                      )}

                      <div className="grid grid-cols-2 gap-4 text-sm mb-6">
                        <div className="flex flex-col items-start">
                          <LabelText text={"Status"} />

                          <div className="flex items-center text-gray-700">
                            <div className="flex items-center">
                              {realTask?.status && (
                                <CustomSelect
                                  state={realTask.states ?? []}
                                  isAssignee={!isAdmin}
                                  data={realTask?.status ?? ""}
                                  isPreview={isAdminViewer}
                                  onChange={(data: any) => {
                                    handleInputChange("status", data.id);
                                    handleSave("status", data.label);
                                    handleCancel("status");
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start">
                          <LabelText text={"Managed By"} />
                          <div className="flex items-center text-gray-700">
                            <User className="w-5 h-5 mr-2 text-blue-500" />
                            {editMode.creator ? (
                              <div className="flex items-center">
                                {selectedTransaction ? (
                                  <div className=" w-40 rounded-md h-8">
                                    <SearchAutoCompleteDropDown
                                      placeholder="Add manager"
                                      width="w-40"
                                      onChange={(e) => {
                                        setManagerEmail(e ?? "");
                                      }}
                                      hint="Enter valid email to add new user"
                                      data={
                                        (userProjectRoles ?? []).map(
                                          (user: UserProjectRoles) => ({
                                            name: user.name,
                                            type: user.email,
                                            id: user.id,
                                          })
                                        ) as SwitchDropDownModel[]
                                      }
                                      getSelectedValue={createManager}
                                      selectedOption={
                                        selectedTransaction as SwitchDropDownModel
                                      }
                                      field={
                                        "name" as keyof SwitchDropDownModel
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Check
                                  className="w-5 h-5 text-green-500 cursor-pointer"
                                  onClick={() => {
                                    handleSave("creator");
                                  }}
                                />
                                <X
                                  className="w-5 h-5 text-red-500 cursor-pointer"
                                  onClick={() => handleCancel("creator")}
                                />
                              </div>
                            ) : (
                              <>
                                <span
                                  onClick={() =>
                                    isAdmin || isAdminAccess
                                      ? handleEdit("creator")
                                      : {}
                                  }
                                  className="text-gray-800 hover:bg-gray-100"
                                >
                                  {!!selectedTransaction?.name
                                    ? selectedTransaction?.name
                                    : "Not Assigned"}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col items-start">
                          <LabelText text={"Start Date"} />
                          <div className="flex items-center text-gray-700">
                            <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                            {editMode.startDate ? (
                              <div className="flex items-center">
                                <input
                                  type="date"
                                  value={editedTask.startDate}
                                  onChange={(e) => {
                                    handleInputChange(
                                      "startDate",
                                      e.target.value
                                    );
                                  }}
                                  className="text-sm border rounded px-2 py-1 mr-2"
                                />
                                <Check
                                  className="w-5 h-5 text-green-500 cursor-pointer"
                                  onClick={() => handleSave("startDate")}
                                />
                                <X
                                  className="w-5 h-5 text-red-500 cursor-pointer"
                                  onClick={() => handleCancel("startDate")}
                                />
                              </div>
                            ) : (
                              <>
                                <span
                                  onClick={() =>
                                    isAdmin || isAdminAccess
                                      ? handleEdit("startDate")
                                      : {}
                                  }
                                  className="text-gray-800 hover:bg-gray-100"
                                >
                                  {formatDisplayDate(realTask?.startDate)}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col items-start">
                          <LabelText text={"Due Date"} />
                          <div className="flex items-center text-gray-700">
                            <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                            {editMode.endDate ? (
                              <div className="flex items-center">
                                <input
                                  type="date"
                                  value={editedTask.endDate}
                                  onChange={(e) =>
                                    handleInputChange("endDate", e.target.value)
                                  }
                                  className="text-sm border rounded px-2 py-1 mr-2"
                                />
                                <Check
                                  className="w-5 h-5 text-green-500 cursor-pointer"
                                  onClick={() => handleSave("endDate")}
                                />
                                <X
                                  className="w-5 h-5 text-red-500 cursor-pointer"
                                  onClick={() => handleCancel("endDate")}
                                />
                              </div>
                            ) : (
                              <>
                                <span
                                  onClick={() =>
                                    isAdmin || isAdminAccess
                                      ? handleEdit("endDate")
                                      : {}
                                  }
                                  className="text-gray-800 hover:bg-gray-100"
                                >
                                  {realTask?.endDate
                                    ? formatDisplayDate(realTask?.endDate)
                                    : "Not Specified"}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col items-start ">
                          <LabelText text={"Priority"} />
                          <div className="flex items-center text-gray-700">
                            <Flag className="w-5 h-5 mr-2 text-blue-500" />
                            {editMode.priority ? (
                              <div className="flex items-center">
                                <select
                                  value={editedTask.priority}
                                  onChange={(e) => {
                                    handleInputChange(
                                      "priority",
                                      e.target.value as
                                        | "NORMAL"
                                        | "LOW"
                                        | "MEDIUM"
                                        | "HIGH"
                                        | "CRITICAL"
                                    );
                                    handleSave("priority", e.target.value);
                                  }}
                                  className="text-sm border rounded px-2 py-1 mr-2"
                                >
                                  <option value="NORMAL">NORMAL</option>
                                  <option value="LOW">LOW</option>
                                  <option value="MEDIUM">MEDIUM</option>
                                  <option value="HIGH">HIGH</option>
                                  <option value="CRITICAL">CRITICAL</option>
                                </select>
                              </div>
                            ) : (
                              <>
                                <span
                                  onClick={() =>
                                    isAdmin || isAdminAccess
                                      ? handleEdit("priority")
                                      : {}
                                  }
                                  className={`px-3 py-1 rounded-full text-sm hover:bg-gray-100 font-medium ${
                                    priorityColor[
                                      realTask?.priority as
                                        | "LOW"
                                        | "NORMAL"
                                        | "MEDIUM"
                                        | "HIGH"
                                        | "CRITICAL"
                                    ]
                                  }`}
                                >
                                  {realTask?.priority ?? "LOW"}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col items-start ">
                          <LabelText text={assignee?.length === 0? "Add Assignee" : "Assigned to"} />
                          <div
                            onClick={() => {
                              handleEdit("assignedTo");
                            }}
                            className="flex hover:bg-gray-100 items-center text-gray-700 cursor-pointer"
                          >
                            {assignee.length ===0
                            ?<UserPlus className="w-5 h-5 mr-2 text-blue-500" />
                            :<Users className="w-5 h-5 mr-2 text-blue-500" />}
                            {assignee && assignee?.length > 0 ? (
                              <span className="text-gray-800">
                                {assignee[0]?.name}
                                {assignee?.length > 1 && (
                                  <span className="text-blue-500">{` & ${
                                    (assignee ?? []).length - 1
                                  } More`}</span>
                                )}
                              </span>
                            ) : (
                              <span className="text-gray-800">
                                {assignee && assignee?.length === 0? 'Tap to add' : "Not Assigned"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {editMode.assignedTo && (
                        <LabelText text={"Assignee's :"} />
                      )}
                      {editMode.assignedTo && (
                        <div className="mb-4">
                          <div className="flex flex-wrap gap-2 mb-2">
                            {(assignee ?? []).map((roles, index) => (
                              <span
                                key={index}
                                className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
                              >
                                {roles.name}
                                {editMode.assignedTo &&
                                  (isAdmin || isAdminAccess) && (
                                    <X
                                      className="w-4 h-4 ml-2 cursor-pointer"
                                      onClick={() => {
                                        handleClickForTracking(
                                          AnalyticsConstant.sidebar_assignee_remove_icon
                                        );
                                        deleteUserFromTask({
                                          projectId: realTasks.projectId,
                                          email: roles.email,
                                          taskId: realTasks.id,
                                        });
                                      }}
                                    />
                                  )}
                              </span>
                            ))}
                          </div>
                          <div className="flex flex-row">
                            {(isAdmin || isAdminAccess) && (
                              <div className="h-10 w-full flex-1">
                                <SearchAutoCompleteDropDown
                                  placeholder="Add assignee"
                                  onChange={(e) => {
                                    setNewAssignee(e ?? "");
                                  }}
                                  data={
                                    (userProjectRoles ?? [])
                                      .map((user: UserProjectRoles) => ({
                                        name: user.name,
                                        type: user.email,
                                        id: user.userId,
                                      }))
                                      .filter((ele) => {
                                        return !assignee.find(
                                          (ele2) => ele2.id === ele.id
                                        );
                                      }) as SwitchDropDownModel[]
                                  }
                                  getSelectedValue={createAssignee}
                                  selectedOption={
                                    selectedAssignee as SwitchDropDownModel
                                  }
                                  field={"name" as keyof SwitchDropDownModel}
                                />
                              </div>
                            )}
                            {(isAdmin || isAdminAccess) &&
                              editMode.assignedTo && (
                                <XMarkIcon
                                  onClick={() => handleCancel("assignedTo")}
                                  className="w-6 h-6 pt-1 ml-1 text-red-500"
                                />
                              )}
                          </div>
                          {(isAdmin || isAdminAccess) &&
                            editMode.assignedTo && (
                              <div className="flex justify-between mt-2">
                                <div className="relative flex items-start">
                                  <div className="flex h-6 items-center">
                                    <input
                                      id="notification"
                                      aria-describedby="notification"
                                      name="notification"
                                      type="checkbox"
                                      checked={sendNotification}
                                      onClick={() => {
                                        setSendNotification(!sendNotification);
                                      }}
                                    />
                                  </div>
                                  <div className="ml-3 text-sm leading-6">
                                    <label
                                      htmlFor="comments"
                                      className="font-medium text-gray-900"
                                    >
                                      Send Notification
                                    </label>
                                  </div>
                                </div>
                                <div></div>
                              </div>
                            )}
                        </div>
                      )}

                      <div className="mb-4 flex flex-wrap items-center gap-2">
                        <LabelText text={"Tags:"} />
                        {(realTask?.tags ?? []).map((tag: any, index: any) => (
                          <span
                            key={index}
                            className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
                          >
                            {tag}
                            {editMode.tags && (
                              <X
                                className="w-4 h-4 ml-2 cursor-pointer"
                                onClick={() => {
                                  handleClickForTracking(
                                    AnalyticsConstant.sidebar_tag_remove_icon
                                  );
                                  setNewTag("");
                                  if (
                                    realTask?.groupName &&
                                    !realTask?.parentTaskId
                                  ) {
                                    setModelOpen({
                                      state: true,
                                      type: "Update SubTask",
                                      data: realTask,
                                      onComplete: (update: boolean) => {
                                        updateTask({
                                          projectId: realTasks.projectId ?? "",
                                          taskId: realTask?.id ?? "",
                                          tags: realTask?.tags?.filter(
                                            (ele: any) => ele !== tag
                                          ),
                                          updateSubTasks: update,
                                        });
                                      },
                                    });
                                  } else {
                                    updateTask({
                                      projectId: realTasks.projectId ?? "",
                                      taskId: realTask?.id ?? "",
                                      updateSubTasks: updateSubTasks,
                                      tags: realTask?.tags?.filter(
                                        (ele: any) => ele !== tag
                                      ),
                                    });
                                  }
                                }}
                              />
                            )}
                          </span>
                        ))}

                        {(isAdmin || isAdminAccess) && !editMode.tags && (
                          <div
                            className="w-4 h-4 text-blue-500 whitespace-nowrap text-xs font-medium cursor-pointer"
                            onClick={() => handleEdit("tags")}
                          >
                            {(realTask?.tags ?? []).length > 0
                              ? "Edit"
                              : "Add Tag"}
                          </div>
                        )}
                      </div>
                      {editMode.tags && (
                        <div className="flex flex-col">
                          <div className="mb-4">
                            <div className=" rounded-md h-8">
                              <SearchStringAutoCompleteDropDown
                                placeholder="Select or Add New Tag"
                                width="w-full"
                                onChange={(e) => {
                                  setNewTag(e ?? "");
                                }}
                                data={
                                  tags?.filter(
                                    (ele) => !realTask?.tags?.includes(ele)
                                  ) ?? []
                                }
                                getSelectedValue={(data) => {
                                  if (data) {
                                    setNewTag(data);
                                    handleSave("tags", data);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex justify-end mt-2">
                            <div
                              onClick={() =>
                                newTag.length > 0 ? handleSave("tags") : {}
                              }
                              className={`text-white ${
                                newTag.length > 0
                                  ? "bg-blue-500 cursor-pointer"
                                  : "bg-gray-500 cursor-not-allowed"
                              } py-1  px-2 text-sm rounded-md`}
                            >
                              Add tag
                            </div>
                            <div
                              onClick={() => handleCancel("tags")}
                              className="text-gray-800 border cursor-pointer border-gray-700 ml-4 bg-white py-1 px-2 text-sm rounded-md"
                            >
                              Cancel
                            </div>
                          </div>
                        </div>
                      )}
                      {realTask?.companyName && (
                        <div className="w-fit flex flex-row items-center">
                          <p className="text-gray-800 text-xs font-medium">
                            {" "}
                            Linked to :
                          </p>
                          <div className="bg-primary/10 text-primary font-semibold gap-2 flex flex-row items-center text-xs px-3 py-1 rounded-full">
                            <Building2 className="w-4 h-4" />
                            <p>{realTask?.companyName}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    {nextRecurringDate && (
                      <div className="inline-flex justify-between items-center bg-blue-100 px-4 py-1.5 text-sm">
                        <HStack className="items-center">
                          <Clock className="h-4 w-4 text-blue-500 mr-1.5" />
                          <span className="font-medium text-blue-700 mr-1.5">
                            Next Recurring:
                          </span>
                          <span className="text-blue-800">
                            {formatDisplayDate(nextRecurringDate)}
                          </span>
                        </HStack>
                        {false && (
                          <HStack className="item-center gap-4">
                            {(isAdmin || isAdminAccess) &&
                              editMode.recurring && (
                                <span
                                  onClick={() => {
                                    handleRecurringSave();
                                  }}
                                  className="font-medium text-blue-700 mr-1.5 cursor-pointer"
                                >
                                  Save
                                </span>
                              )}

                            {(isAdmin || isAdminAccess) && (
                              <span
                                onClick={() => {
                                  !editMode.recurring
                                    ? handleEdit("recurring")
                                    : setEditMode({
                                        ...editMode,
                                        recurring: false,
                                      });
                                }}
                                className="font-medium text-blue-700 mr-1.5 cursor-pointer"
                              >
                                {editMode.recurring ? "Close" : "Edit"}
                              </span>
                            )}
                          </HStack>
                        )}
                      </div>
                    )}

                    {editMode.recurring && (isAdmin || isAdminAccess) && (
                      <div className="px-4 bg-white pt-4">
                        <div className="mb-4">
                          <label
                            htmlFor="intervalMonth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Recurrence Interval
                          </label>
                          <p className="text-xs text-left text-gray-500">
                            Number of months between task repetitions
                          </p>
                          <Input
                            type="number"
                            name="intervalMonth"
                            id="intervalMonth"
                            value={intervalMonth}
                            onChange={(e) =>
                              setIntervalMonth(Number(e.target.value))
                            }
                            required
                          />
                        </div>
                        <HStack className="w-full gap-4">
                          <div className="mb-4  flex-1">
                            <label
                              htmlFor="projectName"
                              className="block text-left text-sm font-medium text-gray-700"
                            >
                              Recurring Due Date
                            </label>
                            <Input
                              type="number"
                              name="recurringEndDate"
                              id="recurringEndDate"
                              placeholder="Between 1 to 30"
                              value={recurringEndDate}
                              onChange={(e) =>
                                setRecurringEndDate(Number(e.target.value))
                              }
                              required
                            />
                          </div>
                          <div className="mb-4 flex-1">
                            <label
                              htmlFor="projectName"
                              className="block  text-left text-sm font-medium text-gray-700"
                            >
                              Recurring Offset Start Date
                            </label>
                            <Input
                              type="number"
                              name="recurringStartDate"
                              id="recurringStartDate"
                              placeholder="Between 1 to 30"
                              value={recurringStartDate}
                              onChange={(e) =>
                                setRecurringStartDate(Number(e.target.value))
                              }
                              required
                            />
                          </div>
                        </HStack>
                        <div className="mb-4 w-full">
                          <label
                            htmlFor="recurringLastDate"
                            className="block text-left text-sm font-medium text-gray-700"
                          >
                            Recuring Last Date
                          </label>
                          <Input
                            type="date"
                            name="recurringLastDate"
                            id="recurringLastDate"
                            value={recurringLastDate}
                            onChange={(e) =>
                              setRecurringLastDate(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                    )}

                    <div className="flex-grow flex flex-col">
                      <div className="flex bg-gray-100">
                        <TabButton
                          value="comments"
                          icon={MessageSquare}
                          label={
                            realTask?.groupName && !realTask?.parentTaskId
                              ? "Announcement"
                              : "Comments"
                          }
                          activeTab={activeTab}
                          onClick={(value: string) => {
                            setActiveTab(value);
                            handleClickForTracking(
                              AnalyticsConstant.sidebar_comment_tab
                            );
                          }}
                          onClose={function (): {} {
                            throw new Error("Function not implemented.");
                          }}
                        />
                        <TabButton
                          value="files"
                          icon={Paperclip}
                          label="Files"
                          activeTab={activeTab}
                          onClick={(value: string) => {
                            setActiveTab(value);
                            handleClickForTracking(
                              AnalyticsConstant.sidebar_file_tab
                            );
                          }}
                          onClose={function (): {} {
                            throw new Error("Function not implemented.");
                          }}
                        />
                        {(isAdmin || isAdminAccess) && (
                          <TabButton
                            value="activity"
                            icon={Activity}
                            label="Activity"
                            activeTab={activeTab}
                            onClick={(value: string) => {
                              setActiveTab(value);
                              handleClickForTracking(
                                AnalyticsConstant.sidebar_activity_tab
                              );
                            }}
                            onClose={function (): {} {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        )}
                      </div>
                      <div className="flex-grow bg-white p-4 overflow-y-auto">
                        {activeTab === "comments" && (
                          <div className="space-y-4 justify-between">
                            <div className="space-y-4 pb-40">
                              {sortByUpdatedAt(comments ?? [], false).map(
                                (comment: any, index: number) => (
                                  <div
                                    key={index}
                                    className={`${
                                      comment.id === updateComment
                                        ? "bg-blue-100"
                                        : "bg-gray-50"
                                    } p-4 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300`}
                                  >
                                    <HStack className="w-full justify-between">
                                      <div className="flex items-center mb-2">
                                        <div className="w-10 h-10 bg-gradient-to-br from-blue-400 to-indigo-500 rounded-full flex items-center justify-center text-white font-semibold mr-3 shadow-md text-sm">
                                          {comment.name.charAt(0)}
                                        </div>
                                        <div>
                                          <p className="font-semibold text-gray-800 text-sm">
                                            {comment.name}
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            {formatDisplayDate(
                                              comment.updatedAt
                                            ) +
                                              "  |  " +
                                              extractDateTimeFromISO(
                                                comment.updatedAt
                                              ).time}
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            {comment.updatedAt ===
                                            comment.createdAt
                                              ? ""
                                              : "Edited"}
                                          </p>
                                        </div>
                                      </div>
                                      {comment.userId === authStore.id ? (
                                        <OptionMenus
                                          options={[
                                            {
                                              name: "Edit Comment",
                                              onClick: () => {
                                                handleClickForTracking(
                                                  AnalyticsConstant.sidebar_comment_edited
                                                );
                                                setUpdateComment(comment.id);
                                                setNewComment(comment.content);
                                              },
                                            },
                                            {
                                              name: "Delete Comment",
                                              onClick: async () => {
                                                handleClickForTracking(
                                                  AnalyticsConstant.sidebar_comment_deleted
                                                );
                                                await deleteComment(
                                                  comment.id,
                                                  comment.taskId
                                                );
                                                queryClient.invalidateQueries(
                                                  "getAllComment"
                                                );
                                              },
                                            },
                                          ]}
                                        />
                                      ) : (
                                        <p></p>
                                      )}
                                    </HStack>

                                    <p className="text-sm text-gray-700 mt-2 leading-relaxed">
                                      {comment.content}
                                    </p>
                                  </div>
                                )
                              )}
                              {comments && comments.length === 0 && (
                                <CommentEmptyScreen />
                              )}
                            </div>
                            <div className="mt-4 absolute pb-4 bottom-0 left-4 right-4 bg-white z-50">
                              <textarea
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Enter your comment here..."
                                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                                rows={3}
                              />
                              <button
                                onClick={handleAddComment}
                                className="mt-3 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center w-full text-sm"
                              >
                                {updateComment ? (
                                  <Edit className="w-5 h-5 mr-2" />
                                ) : (
                                  <Send className="w-5 h-5 mr-2" />
                                )}
                                {updateComment ? "Edit Comment" : "Add Comment"}
                              </button>
                            </div>
                          </div>
                        )}

                        {activeTab === "files" && (
                          <div>
                            <HStack>
                              <div className="relative flex items-start px-4">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="comments"
                                    aria-describedby="comments-description"
                                    name="comments"
                                    type="checkbox"
                                    checked={showProjectFile}
                                    onClick={() => {
                                      setShowProjectFile(!showProjectFile);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                  <label
                                    htmlFor="comments"
                                    className="font-medium text-left text-gray-900 whitespace-nowrap"
                                  >
                                    Show Project Attachments
                                  </label>
                                </div>
                              </div>
                              <label
                                onClick={() => {
                                  handleClickForTracking(
                                    AnalyticsConstant.sidebar_add_link
                                  );
                                  setModelOpen({
                                    data: {
                                      id: realTasks.projectId,
                                      taskId: realTasks.id,
                                    },
                                    type: "Link",
                                    state: true,
                                  });
                                }}
                                className="cursor-pointer  justify-end w-full whitespace-nowrap text-blue-600 px-4 py-2 rounded-md transition-colors duration-300 flex items-center  text-sm"
                              >
                                <Plus className="w-5 h-5 mr-2" />
                                Add Link
                              </label>
                            </HStack>

                            <ul className="space-y-3 mb-8">
                              {showProjectFile &&
                                projectDocuments.map(
                                  (file: TaskDocument, index: any) => (
                                    <FileItem
                                      key={file.id}
                                      file={file}
                                      isProject={true}
                                      isUser={true}
                                      onDownload={handleDownload}
                                      onDelete={() =>
                                        handleDelete(file.id, realTasks.id)
                                      }
                                      view={view}
                                      projectId={realTasks.projectId}
                                      taskId={realTasks.id}
                                      isSelected={selectedFiles.some(
                                        (f) => f.id === file.id
                                      )}
                                      onSelect={handleSelectFile}
                                    />
                                  )
                                )}
                              {taskDocuments.map(
                                (file: TaskDocument, index: any) => (
                                  <FileItem
                                    key={file.id}
                                    file={file}
                                    isUser={true}
                                    onDownload={handleDownload}
                                    onDelete={() =>
                                      handleDelete(file.id, realTasks.id)
                                    }
                                    view={view}
                                    projectId={realTasks.projectId}
                                    taskId={realTasks.id}
                                    isSelected={selectedFiles.some(
                                      (f) => f.id === file.id
                                    )}
                                    onSelect={handleSelectFile}
                                  />
                                )
                              )}
                              {((taskDocuments.length === 0 &&
                                !showProjectFile) ||
                                (showProjectFile &&
                                  projectDocuments.length === 0)) && (
                                <DocumentEmptyScreen />
                              )}
                            </ul>
                            <CustomDownloadDialog
                              isOpen={isDownloading}
                              selected={selectedFiles}
                              onOpenChange={setIsDownloading}
                              projectId={realTask?.projectId ?? ""}
                              isProject={projectDocuments.find((docs: any) =>
                                selectedFiles.find(
                                  (files: any) =>
                                    files.location === docs.location
                                )
                              )}
                            />
                            <div className="mt-4 flex flex-row gap-4 fixed bottom-4 right-8">
                              {selectedFiles.length > 0 && (
                                <button
                                  onClick={() => {
                                    handleClickForTracking(
                                      AnalyticsConstant.sidebar_download_selected
                                    );
                                    setIsDownloading(!isDownloading);
                                  }}
                                  className="flex whitespace-nowrap items-center px-3 py-1 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100"
                                >
                                  <Download className="w-4 h-4 mr-1" />
                                  Download Selected ({selectedFiles.length})
                                </button>
                              )}
                              <label
                                htmlFor="file-upload"
                                className="cursor-pointer text-left bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center w-full text-sm"
                              >
                                <Plus className="w-5 h-5 mr-2" />
                                Add Attachments
                                <input
                                  id="file-upload"
                                  type="file"
                                  className="hidden"
                                  multiple
                                  onChange={handleAddFile}
                                />
                              </label>
                            </div>
                          </div>
                        )}

                        {activeTab === "activity" &&
                          (isAdmin || isAdminAccess) && (
                            <div className="-m-4">
                              <ActivitiesSection
                                activities={history?.sort(compareHistory) ?? []}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SideBarPanel;

export const LabelText = ({ text }: { text: string }) => {
  return (
    <p className="text-xs pb-0.5 text-gray-500 font-medium tracking-wide leading-relaxed">
      {text}
    </p>
  );
};
