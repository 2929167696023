import { useMutation, useQuery } from "react-query";
import { captableSignIn, forgotPassword } from "../api/login";
import { ResetPostPasswordDetailModel } from "../types/CaptableLoginModel";
import { handleEventForTracking } from "../analytics";
import api from "./api";
import captableApi from "./cpatableApi";
import { deleteTableState, getTableFilterState, postTableFilterState } from "../api/project";
import { queryClient } from "./client";

export async function resetPassword(
  resetPasswordDetail: ResetPostPasswordDetailModel
): Promise<{ message: string; data: any }> {
  return captableApi.post(`auth/resetPassword`, resetPasswordDetail, {
    headers: { authorization: resetPasswordDetail.jwt },
  });
}


export function useResetPassword() {
  return useMutation({
    mutationKey: "resetPassword",
    mutationFn: resetPassword,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "resetPassword",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "resetPassword",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useForgotPassword() {
  return useMutation({
    mutationKey: "forgotPassword",
    mutationFn: forgotPassword,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "forgotPassword",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "forgotPassword",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCaptableSignIn() {
  return useMutation({
    mutationKey: "captableSignIn",
    mutationFn: captableSignIn,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "captableSignIn",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "captableSignIn",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetTableFilterState(tableId: string) {
  return useQuery({
    queryKey: ["getTableFilterState", tableId],
    queryFn: getTableFilterState,
    cacheTime: 0,
    staleTime: 0
  });
}

export function usePostTableFilterState() {
  return useMutation({
    mutationKey: "postTableFilterState",
    mutationFn: postTableFilterState,
  });
}

export function useDeleteTableState() {
  return useMutation({
    mutationKey: "deleteTableState",
    mutationFn: deleteTableState,
    onSuccess: () => {
      queryClient.invalidateQueries("getTableFilterState");
    },
  });
}
