import { useEffect, useState } from "react";
import _ from "lodash";
import { useAuthStore } from "../../store/useAuthStore";
import { logoImage } from "../project/TableIdConstants";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";
import { useApproveAmp } from "../../queries/admin";

const TaskApprovalEmail = () => {
  const [params, setParams] = useState({});
  const [userDetail, setUserDetails] = useState<any>(null);
  const [approvalState, setApprovalState] = useState("PENDING");
  const [showApproving, setShowApproving] = useState(false);
  const [showRevoking, setShowRevoking] = useState(false);
  const [comment, setComment] = useState("");
  const { setAuth } = useAuthStore();
  useEffect(() => {
    const extractParams = () => {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const paramsObject = Object.fromEntries(searchParams.entries());

      setParams(paramsObject);
      if (paramsObject && paramsObject["userName"]) {
        setUserDetails({
          userName: paramsObject["userName"] ?? "",
          taskName: paramsObject.taskName || "",
          projectName: paramsObject.projectName || "",
          taskDescription: paramsObject.taskDescription || "",
          viewDetailsUrl: paramsObject.taskLink || "",
          endDate: paramsObject.endDate || "",
          stateChangeUserName: paramsObject.stateChangeUserName || "",
          status: paramsObject.status || "Completed",
          id: paramsObject.id || "",
        });
        const token = paramsObject["token"];
        setAuth({
          accessToken: token ?? "",
        });
      }


      const newUrl = `${window.location.origin}${window.location.pathname}`;
      window.history.replaceState({}, "", newUrl);
    };

    extractParams();

    return () => {

    };
  }, []);

  const { mutate: approveAmp } = useApproveAmp();

  const handleApprovalToggle = async () => {
    setApprovalState("APPROVING");
    if (approvalState === "APPROVED") setShowRevoking(true);
    setShowApproving(true);

    await new Promise((resolve) => setTimeout(resolve, 1000)); 

    try {
      if (approvalState === "APPROVED") {
        handleClickForTracking(AnalyticsConstant.mail_revoke_button);
        approveAmp({
          state:"INPROGRESS", 
          comment:comment
        },{});
        setShowRevoking(false);
        setApprovalState("PENDING");
        setComment("");
      } else {
        handleClickForTracking(AnalyticsConstant.mail_approval_button);
        approveAmp({
          state:"CLOSED", 
          comment:comment
        },{});
        setApprovalState("APPROVED");
        setComment("");
      }
    } catch (error) {}

    setShowApproving(false);
  };

  if (!userDetail)
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );

  return (
    <div className="font-sans leading-normal bg-[#f2f5f8] m-0 p-0 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-[600px] mx-auto my-5 bg-white rounded-lg overflow-hidden shadow-md">
        <div className="bg-gradient-to-b from-[#0c224a] to-[#2158be] p-10 text-center">
          <img src={logoImage} alt="Hissa" className="w-24 h-10 mx-auto mb-4" />
          <h1 className="text-white text-2xl font-bold">
            Task Approval Needed
          </h1>
        </div>

        <div className="p-8">
          <p>
            Hi <strong>{userDetail.userName}</strong>,
          </p>
          <br />
          <p>
            The status for the task titled '{userDetail.taskName}'' under the
            project '{userDetail.projectName}' has been updated to '
            {userDetail.status}' by {userDetail.stateChangeUserName}.
          </p>
          <br />
          <p>Task Details:</p>
          <div className="bg-[#f8f8f8] rounded-md p-5 mb-5">
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Task Description:
            </h2>
            <p className="text-gray-600 pl-5">{userDetail.taskDescription}</p>
          </div>
          <h2 className="text-lg font-semibold text-gray-800 mt-6">
            Next Steps:
          </h2>
          <p>
            The task status requires your approval. Please click on{" "}
            <strong>Approve Task</strong> to close the task and add comments if
            required.
          </p>
          <h2 className="text-lg font-semibold text-gray-800 mt-6 mb-2">
            Comments:
          </h2>
          <textarea
            className="w-[100%] p-2 border border-gray-300 rounded-md resize-vertical min-h-[80px]"
            placeholder="Add your comments here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {approvalState === "APPROVED" && (
            <div
              className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6 rounded"
              role="alert"
            >
              <p className="font-bold">Approved</p>
              <p>This task has been approved.</p>
            </div>
          )}
          <div className="text-center mt-6">
            <button
              onClick={handleApprovalToggle}
              disabled={showApproving}
              className={`inline-block px-6 py-2 text-white rounded-md transition-all duration-300 ease-in-out ${
                approvalState === "APPROVED"
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-[#2158be] hover:bg-[#1a4699]"
              }`}
            >
              {showApproving ? (
                <span className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {showRevoking ? "Revoking..." : "Approving..."}
                </span>
              ) : approvalState === "APPROVED" ? (
                "Revoke Approval"
              ) : (
                "Approve Task"
              )}
            </button>
          </div>
          <p className="mt-6">
            Regards,
            <br />
            <strong>Team Hissa</strong>
          </p>
        </div>

        <div className="bg-[#f2f5f8] text-center p-5">
          <p className="text-sm text-gray-500">
            Copyright © 2024-Rulezero, All rights reserved.
            <br />
            Hissa is a registered trademark of Rulezero.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TaskApprovalEmail;
