import React, { useState, useMemo, useRef, useEffect } from "react";
import { TaskModel } from "../myTask/SideBarPanel";

interface TagFilterProps {
  data: TaskModel[];
  onSelect?: any;
}

const TagFilter: React.FC<TagFilterProps> = ({ data, onSelect = "" }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Extract unique tags from data
  const allTags = useMemo(() => {
    const tagSet = new Set<string>();
    data.forEach((item) => {
      (item?.tags ?? []).forEach((tag) => tagSet.add(tag));
    });
    return Array.from(tagSet);
  }, [data]);

  // State for selected tags
  const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());

  // Handle checkbox changes
  const handleTagChange = (tag: string): void => {
    const newSelected = new Set(selectedTags);
    if (newSelected.has(tag)) {
      newSelected.delete(tag);
    } else {
      newSelected.add(tag);
    }
    setSelectedTags(newSelected);
    onSelect(newSelected);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white px-2 py-1 h-10 text-sm rounded-lg shadow flex items-center justify-between w-"
        type="button"
      >
        <span className="text-gray-700 sm:block hidden whitespace-nowrap">
          {selectedTags.size
            ? `${selectedTags.size} tags selected`
            : "Filter by Tags"}
        </span>
        <span className="text-gray-700 sm:hidden block whitespace-nowrap">
          {selectedTags.size ? `${selectedTags.size} tags` : "Tags"}
        </span>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute top-full mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10">
          {allTags.map((tag) => (
            <label
              key={tag}
              className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              <input
                type="checkbox"
                checked={selectedTags.has(tag)}
                onChange={() => handleTagChange(tag)}
                className="w-4 h-4 mr-2"
              />
              <span className="text-gray-700">{tag}</span>
            </label>
          ))}
          {allTags.length === 0 && (
            <label
              key={"No Tags"}
              className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              <span className="text-gray-700 text-sm">
                {"No Tags Available"}
              </span>
            </label>
          )}
        </div>
      )}
    </div>
  );
};

export default TagFilter;
