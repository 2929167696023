import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { CellClickedEvent, ColDef, GetDataPath } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../../shared/CTAButtonComponents";
import {
  EndDate,
  ManagerName,
  Priority,
  ProjectName,
  StartDate,
  tableCellStyle,
  TaskDescriptionRender,
  TaskNameRender,
  TaskTagRender,
} from "./AgComponent";
import SearchInput from "../myTask/SearchInput";
import { Action, CTADropdown } from "../../shared/Dropdown";
import { Box, HStack, VStack } from "../../shared/utils";
import { agConfigDashboardSideBar } from "./AgConfig";
import { CustomSelect } from "../myTask/UserStateChange";
import { TaskExtractModel } from "../myTask/useExtractMyTask";
import {
  Archive,
  Bell,
  BookmarkPlus,
  CopyPlus,
  Flag,
  PlugIcon,
  Trash,
} from "lucide-react";
import DeleteTaskModal from "../../modal/DeleteTask";
import SideBarAddTaskPanel from "../myTask/SideBarAddTaskPanel";
import SideBarPanel from "../myTask/SideBarPanel";
import { useAuthStore } from "../../store/useAuthStore";
import MailReminderModal from "../../modal/MailReminder";
import { formatDate } from "../../utils/date";
import { UserSelectComponent } from "../myTask/UserDropDown";
import { MultiSelectFilterTab } from "../myTask/MultiSelectTab";
import { combinedSort } from "../myTask/taskUtils";
import "./agstyle.css";
import CloudSetting from "../project/CloudIcon";
import { useTableStateManagement } from "../project/TableHook";
import CalendarPage from "../calender/CalenderPage";
import useIsMobile from "../../utils/detectDevice";
import { useGetUserProjectRoles } from "../../queries/userRole";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";
import KanbanBoard from "../kanban/page";
import { MultiSelectFilterTabWithoutAll } from "../myTask/MultiSelectTab2";
import LinkCompanyModal from "../../modal/LinkCompany";
import TagFilter from "./FilterTags";

export interface SwitchDropDownModel {
  name: string;
  type: string;
  id: string;
}

export default function AGGridSimpleTable({
  tableId,
  tasks,
  project,
}: {
  tableId: string;
  tasks: any[];
  project?: any;
}) {
  const [selectedProjectType, setSelectedProjectType] =
    useState<string>("PROJECT");
  const [projectType, setProjectType] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setPageLoaded(true);
    if (project) {
      setStatuses(project.states);
      setProjectType([project.projectType]);
      setSelectedProjectType(project.projectType);
    }
    let type: any[] = [];
    if (tasks) {
      tasks.forEach((task) => {
        if (!type.includes(task.projectType)) {
          type.push(task.projectType);
        }
      });

      const data2 = tasks.find((ele) => ele.projectType === "PROJECT");
      if (data2) setStatuses(data2.states);
      const membersMap = new Map<string | number, any>();
      tasks.forEach((task: TaskExtractModel) => {
        task.userTaskRoles.forEach((role) => {
          if (role?.id) {
            membersMap.set(role.userId, { id: role.userId, name: role.name });
          }
        });
      });

      setUsers(Array.from(membersMap.values()));
    }

    if (!project) setProjectType(type);
  }, [project, tasks]);

  const [includedProjectName, setIncludeProjectName] = useState(false);
  const { isMobile } = useIsMobile();
  const [selectedUserIds, setSelectedUserIds] = useState<any>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(["ALL"]);

  const companyId = "";
  const isAdmin = useAuthStore.getState().role === "ADMIN";
  const { isSubscribed } = useAuthStore();
  const userId = useAuthStore.getState().id;
  const gridApi = useRef<any>(null);
  const [users, setUsers] = useState<any[]>([]);

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const componentsRegistery = useMemo(
    () => ({
      taskName: memo(TaskNameRender),
      taskDescription: memo(TaskDescriptionRender),
      taskEndDate: memo(EndDate),
      taskTag: memo(TaskTagRender),
      taskStartDate: memo(StartDate),
      projectName: memo(ProjectName),
      companyName: memo(ProjectName),
      priority: memo(Priority),
      taskManager: memo(ManagerName),
    }),
    [],
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    [],
  );

  const caseInsensitiveComparator = (valueA: any, valueB: any) => {
    const stringA = valueA.props.data
      ? valueA.props.data.toString().toLowerCase()
      : "";
    const stringB = valueB.props.data
      ? valueB.props.data.toString().toLowerCase()
      : "";

    if (stringA < stringB) {
      return -1;
    } else if (stringA > stringB) {
      return 1;
    } else {
      return 0;
    }
  };
  const columnDefs: ColDef[] = useMemo(() => {
    const colDef: ColDef[] = [
      {
        headerName: "Description",
        field: "description",
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
        sortable: false,
        hide: isMobile ? true : false,
        flex: 1,
        width: 100,
        cellRenderer: "taskDescription",
        tooltipField: "description",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agSetColumnFilter",
        width: 100,
        minWidth: 200,
        valueFormatter: (params: any) => {
          return params.value.props?.data;
        },
        menuTabs: ["filterMenuTab"],
        sortable: true,
        comparator: caseInsensitiveComparator,
        getQuickFilterText: (params: any) => {
          return params.data.statusLabel.toLowerCase();
        },
        cellStyle: { paddingTop: "16px", lineHeight: "20px" },
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CustomSelect,
      },
      {
        headerName: "Priority",
        field: "priority",
        menuTabs: ["filterMenuTab"],

        hide: isMobile ? true : false,
        width: 100,
        cellRenderer: "priority",
        cellStyle: tableCellStyle,
      },

      {
        headerName: "Start Date",
        field: "startDate",
        filter: "agDateColumnFilter",
        menuTabs: ["filterMenuTab"],

        width: 180,
        hide: true,

        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "taskStartDate",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Due date",
        field: "endDate",
        menuTabs: ["filterMenuTab"],
        width: 180,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "taskEndDate",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Tag",
        field: "tags",
        menuTabs: ["filterMenuTab"],
        cellRenderer: "taskTag",
        hide: true,
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Assignee",
        field: "assignee",
        hide: true,
        menuTabs: [],
        cellRenderer: "taskManager",
        tooltipField: "assigneeToolTip",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Manager",
        field: "manager",
        tooltipField: "managerToolTip",

        hide: true,
        cellRenderer: "taskManager",
        menuTabs: [],
        cellStyle: tableCellStyle,
        colId: "manager",
      },
      {
        headerName: "",
        field: "quickactions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ];
    if (!project) {
      colDef.push({
        headerName: "Project Name",
        field: "projectName",
        menuTabs: ["filterMenuTab"],
        hide: true,
        cellRenderer: "projectName",
        cellStyle: tableCellStyle,
      });
    }
    if (!project) {
      colDef.push({
        headerName: "Company Name",
        field: "companyName",
        menuTabs: ["filterMenuTab"],
        hide: true,
        cellRenderer: "companyName",
        cellStyle: tableCellStyle,
      });
    }

    return colDef;
  }, []);

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  async function handleAction(action: Action) {}

  const [modalOpen, setModelOpen] = useState<{
    state: boolean;
    type: "Delete" | "Reminder" | "Duplicate" | "Link Company";
    data: any;
  }>({
    type: "Delete",
    state: false,
    data: undefined,
  });
  const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());

  const { data: userProjectRoles } = useGetUserProjectRoles(project?.id ?? "");

  const sortedTask = useMemo(() => {
    return tasks
      .filter((ele: TaskExtractModel) => {
        const matchesSearch = searchTerm
          ? ele.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (ele.description?.toLowerCase() || "").includes(
              searchTerm.toLowerCase(),
            )
          : true;

        return (
          matchesSearch &&
          ele.projectType === selectedProjectType &&
          (selectedFilters.includes(ele.status) ||
            selectedFilters.includes("ALL")) &&
          (ele.userTaskRoles.find((users) =>
            (selectedUserIds ?? []).includes(users.userId),
          ) ||
            selectedUserIds.length === 0) &&
          ele.isActive
        );
      })
      .filter((item) => {
        if (selectedTags.size !== 0) {
          return (item?.tags ?? []).some((tag: string) =>
            selectedTags?.has(tag),
          );
        } else return true;
      })
      .sort(combinedSort);
  }, [
    tasks,
    selectedTags,
    selectedProjectType,
    selectedFilters,
    selectedUserIds,
    searchTerm,
  ]);

  const groupTaskList = useMemo(
    () =>
      sortedTask.filter((ele: TaskExtractModel) => {
        return !ele.parentTaskId && ele.groupName;
      }),
    [tasks],
  );

  function getGroupMembers(groupTask: any){
    const members:any[]=[]
    tasks.forEach((task) => {
      
      if (task.parentTaskId === groupTask.id || (task.parentTaskId === null && task.id === groupTask.id)){
        task.userTaskRoles.forEach((role:any) =>{
         
            const isAlreadyAdded = members.some((member) => member.userId === role.userId && member.role === role.role);
            if (!isAlreadyAdded) {
              members.push(role);
            }
          
        })
      }
   
    })
    return members
  }
  const rowData = useMemo(
    () =>
      sortedTask
        .filter(
          (ele: TaskExtractModel) => !(!ele.parentTaskId && ele.groupName),
        )
        .flatMap((task: TaskExtractModel, index: number) => {
          let actions = null;
          let isManager = !!task.userTaskRoles.find(
            (role) => role.userId === userId && role.role === "ASSIGNER",
          );
          let isAdmin = !!userProjectRoles?.find(
            (role) => role.userId === userId && role.role === "ADMIN",
          );

          const groupTask = groupTaskList.find(
            (oldTask) => oldTask.id === task.parentTaskId,
          );
          const quickactions =
            isManager || isAdmin ? (
              <CTADropdown
                actions={
                  isManager || isAdmin
                    ? [
                        {
                          data: task,
                          name: "Flag",
                          icon: <Flag className="w-3 h-3" />,
                          disabled: true,
                        },
                        {
                          data: task,
                          name: "Archieve",
                          icon: <Archive className="w-3 h-3" />,
                          disabled: true,
                        },
                        {
                          data: task,
                          name: "Reminder",
                          icon: <Bell className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: task,
                          name: "Duplicate",
                          icon: <CopyPlus className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: task,
                          name: "Delete",
                          icon: <Trash className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: task,
                          name: "Link Company",
                          icon: <PlugIcon className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                      ]
                    : []
                }
                onAction={(action) => {
                  if (action.name === "Delete")
                    setModelOpen({
                      type: "Delete",
                      data: action.data,
                      state: true,
                    });
                  else if (action.name === "Reminder") {
                    setModelOpen({
                      type: "Reminder",
                      data: action.data,
                      state: true,
                    });
                  } else if (action.name === "Link Company") {
                    setModelOpen({
                      type: "Link Company",
                      data: action.data,
                      state: true,
                    });
                  } else if (action.name === "Duplicate") {
                    setModelOpen({
                      type: "Duplicate",
                      data: action.data,
                      state: true,
                    });
                  }
                }}
              />
            ) : (
              <></>
            );
          const members = groupTask && getGroupMembers(groupTask) || []

          const groupQuickActions =
            isManager || isAdmin ? (
              <CTADropdown
                actions={
                  isManager || isAdmin
                    ? [
                        {
                          data: groupTask,
                          name: "Flag",
                          icon: <Flag className="w-3 h-3" />,
                          disabled: true,
                        },
                        {
                          data: task,
                          name: "Archieve",
                          icon: <Archive className="w-3 h-3" />,
                          disabled: true,
                        },
                        {
                          data: task,
                          name: "Reminder",
                          icon: <Bell className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: task,
                          name: "Duplicate",
                          icon: <CopyPlus className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: groupTask,
                          name: "Delete",
                          icon: <Trash className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: task,
                          name: "Link Company",
                          icon: <PlugIcon className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                      ]
                    : []
                }
                onAction={(action) => {
                  if (action.name === "Delete")
                    setModelOpen({
                      type: "Delete",
                      data: action.data,
                      state: true,
                    });
                  else if (action.name === "Reminder") {
                    setModelOpen({
                      type: "Reminder",
                      data: action.data,
                      state: true,
                    });
                  } else if (action.name === "Link Company") {
                    setModelOpen({
                      type: "Link Company",
                      data: action.data,
                      state: true,
                    });
                  } else if (action.name === "Duplicate") {
                    setModelOpen({
                      type: "Duplicate",
                      data: {...action.data, userTaskRoles: members.length > 0 ? members : action.data.userTaskRoles },
                      state: true,
                    });
                  }
                }}
              />
            ) : (
              <></>
            );

          actions = (
            <CustomSelect
              isAssignee={false}
              isPreview={true}
              data={task.status ?? ""}
              onChange={(data: any) => {}}
              state={statuses ?? []}
            />
          );

          let roles = task?.userTaskRoles ?? [];
          let groupRoles = [];
          let groupAction;
          let endDate = formatDate(task?.endDate ?? "");
          let startDate = formatDate(task?.startDate ?? "");
          let groupEndDate = "";
          let groupStartDate = "";

          const assignee = task.userTaskRoles.find(
            (ele) => ele.role === "ASSIGNEE",
          )?.name;

          if (groupTask) {
            groupRoles = groupTask?.userTaskRoles ?? [];
            groupAction = (
              <CustomSelect
                isAssignee={false}
                data={groupTask.status ?? ""}
                onChange={(data: any) => {}}
                state={statuses ?? []}
              />
            );
            groupEndDate = formatDate(groupTask?.endDate ?? "");
            groupStartDate = formatDate(groupTask?.startDate ?? "");
          }

          return includedProjectName && !project
            ? [
                {
                  id: task.id,
                  orgHierarchy: [task.projectName],
                  taskName: {
                    name: task.projectName,
                    type: task.companyName,
                  },
                  tooltipTaskName: task.name,
                  isRecurring: task.isRecurring ?? false,
                  description: task.projectDescription,
                  endDate: ``,
                  name: task.name,
                  tags: "",
                  priority: "",
                  projectId: task.projectId,
                  projectName: "",
                  projectDescription: task.projectDescription,
                  companyName: task.companyName,
                  projectSpecificStatusId: task.projectSpecificStatusId,
                  startDate: "",
                  status: "",
                  statusLabel: "",
                  quickactions: "",
                  isRead: "",
                  manager: "-",
                  assignee: "-",
                  assigneeToolTip: "-",
                  managerToolTip: "-",
                },
                {
                  id: task.id,
                  orgHierarchy: [task.projectName, task.name],
                  taskName: {
                    name: task.name,
                    type: task.companyName,
                  },
                  projectDescription: task.projectDescription,
                  tooltipTaskName: task.name,
                  isRecurring: task.isRecurring ?? false,
                  description: task.description,
                  endDate: `${endDate}`,
                  name: task.name,

                  tags: task.tags,
                  priority: task.priority,
                  projectId: task.projectId,
                  projectName: task.projectName,
                  companyName: task.companyName,
                  projectSpecificStatusId: task.projectSpecificStatusId,
                  startDate: `${startDate}`,
                  status: actions,
                  statusLabel: task.status,
                  quickactions: quickactions,
                  isRead: task.isRead ?? false,
                  manager: roles
                    .filter((user) => user.role === "ASSIGNER")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                  assigneeToolTip: roles
                    .filter((user) => user.role === "ASSIGNEE")
                    .map((user) => user.name)
                    .join(", "),
                  managerToolTip: roles
                    .filter((user) => user.role === "ASSIGNER")
                    .map((user) => user.name)
                    .join(", "),
                  assignee: roles
                    .filter((user) => user.role === "ASSIGNEE")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                },
              ]
            : task.groupName && groupTask
              ? [
                  {
                    id: groupTask.id,
                    isGroup: true,
                    orgHierarchy: [groupTask.groupName],
                    taskName: {
                      name: groupTask.name,
                      type: groupTask.companyName,
                    },
                    isRecurring: groupTask.isRecurring ?? false,
                    projectDescription: groupTask.projectDescription,
                    tooltipTaskName: groupTask.name,
                    description: groupTask.description,
                    endDate: `${groupEndDate}`,
                    name: groupTask.name,
                    tags: groupTask.tags,
                    priority: groupTask.priority,
                    projectId: groupTask.projectId,
                    projectName: groupTask.projectName,
                    companyName: groupTask.companyName,
                    projectSpecificStatusId: groupTask.projectSpecificStatusId,
                    startDate: `${groupStartDate}`,
                    status: actions,
                    statusLabel: groupTask.status,
                    quickactions: groupQuickActions,
                    isRead: groupTask.isRead ?? false,
                    manager: groupRoles
                      .filter((user: any) => user.role === "ASSIGNER")
                      .map((user: any) => ({
                        id: user.id,
                        name: user.name,
                      })),
                    assigneeToolTip: groupRoles
                      .filter((user: any) => user.role === "ASSIGNEE")
                      .map((user: any) => user.name)
                      .join(", "),
                    managerToolTip: groupRoles
                      .filter((user: any) => user.role === "ASSIGNER")
                      .map((user: any) => user.name)
                      .join(", "),
                    assignee: groupRoles
                      .filter((user: any) => user.role === "ASSIGNEE")
                      .map((user: any) => ({
                        id: user.id,
                        name: user.name,
                      })),
                  },

                  {
                    isGroup: true,
                    id: task.id,
                    orgHierarchy: [task.groupName, assignee ?? "None"],
                    taskName: {
                      name: task.name,
                      type: task.companyName,
                    },
                    projectDescription: task.projectDescription,
                    tooltipTaskName: task.name,
                    description: task.description,
                    isRecurring: task.isRecurring ?? false,
                    endDate: `${endDate}`,
                    name: task.name,
                    tags: task.tags,
                    priority: task.priority,
                    projectId: task.projectId,
                    projectName: task.projectName,
                    companyName: task.companyName,
                    projectSpecificStatusId: task.projectSpecificStatusId,
                    startDate: `${startDate}`,
                    status: actions,
                    statusLabel: task.status,
                    quickactions: quickactions,
                    isRead: task.isRead ?? false,
                    assigneeToolTip: roles
                      .filter((user: any) => user.role === "ASSIGNEE")
                      .map((user: any) => user.name)
                      .join(", "),
                    managerToolTip: roles
                      .filter((user: any) => user.role === "ASSIGNER")
                      .map((user: any) => user.name)
                      .join(", "),
                    manager: roles
                      .filter((user) => user.role === "ASSIGNER")
                      .map((user) => ({
                        id: user.id,
                        name: user.name,
                      })),
                    assignee: roles
                      .filter((user) => user.role === "ASSIGNEE")
                      .map((user) => ({
                        id: user.id,
                        name: user.name,
                      })),
                  },
                ]
              : {
                  id: task.id,
                  orgHierarchy: [task.name],
                  taskName: {
                    name: task.name,
                    type: task.companyName,
                  },
                  projectDescription: task.projectDescription,
                  tooltipTaskName: task.name,
                  description: task.description,
                  endDate: `${endDate}`,
                  isRecurring: task.isRecurring ?? false,
                  name: task.name,
                  tags: task.tags,
                  priority: task.priority,
                  projectId: task.projectId,
                  projectName: task.projectName,
                  companyName: task.companyName,
                  projectSpecificStatusId: task.projectSpecificStatusId,
                  startDate: `${startDate}`,
                  status: actions,
                  statusLabel: task.status,
                  quickactions: quickactions,
                  isRead: task.isRead ?? false,
                  assigneeToolTip: roles
                    .filter((user: any) => user.role === "ASSIGNEE")
                    .map((user: any) => user.name)
                    .join(", "),
                  managerToolTip: roles
                    .filter((user: any) => user.role === "ASSIGNER")
                    .map((user: any) => user.name)
                    .join(", "),
                  manager: roles
                    .filter((user) => user.role === "ASSIGNER")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                  assignee: roles
                    .filter((user) => user.role === "ASSIGNEE")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                };
        }),
    [
      // tasks,
      sortedTask,
      // selectedFilters,
      // selectedUserIds,
      // includedProjectName,
      // userProjectRoles,
      // selectedProjectType,
    ],
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.78) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.94) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.7) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }
  const [pageLoaded, setPageLoaded] = useState(false);
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    refetch,
  } = useTableStateManagement(gridApi, tableId, pageLoaded);

  const [isUpdateProjectDetails, setUpdateProjectDetails] = useState<{
    state: boolean;
    task?: TaskExtractModel;
  }>({
    state: false,
    task: undefined,
  });

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;

      setUpdateProjectDetails({
        task: template,
        state: true,
      });
    }
  };
  const contextRef = useRef<HTMLDivElement>(null);

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleUserChange = (newSelectedUsers: any) => {
    setSelectedUserIds(newSelectedUsers);
  };
  const gridContainerRef = useRef(null);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);

    uploadFilter();
  };

  const [isCreateTask, setCreateTask] = useState(false);

  const handleFilterChange = (newFilters: any) => {
    // if (newFilters.find((filter: any) => !selectedFilters.includes(filter))) {
    handleClickForTracking(AnalyticsConstant.list_status_filter_tab);
    setSelectedFilters(newFilters);
    // }
  };
  const handleTypeChange = (newFilters: any) => {
    handleClickForTracking(AnalyticsConstant.list_project_filter_tab);
    if (newFilters !== selectedProjectType) {
      const newType = tasks.find((task) => task.projectType === newFilters);
      setStatuses(newType.states);
      setSelectedFilters((prev) => ["ALL"]);
      setSelectedProjectType(newFilters);
    }
    // setSelectedFilters(["ALL"]);
  };

  const getDataPath = useCallback<GetDataPath>((data) => data.orgHierarchy, []);
  const comparator = (
    valueA: string,
    valueB: string,
    nodeA: any,
    nodeB: any,
  ) => {
    // Handle undefined or null values
    if (!valueA) return valueB ? -1 : 0;
    if (!valueB) return 1;

    // Get the full hierarchy paths
    const hierarchyA = nodeA.data.orgHierarchy;
    const hierarchyB = nodeB.data.orgHierarchy;

    // Compare hierarchy levels
    const minLength = Math.min(hierarchyA.length, hierarchyB.length);

    for (let i = 0; i < minLength; i++) {
      if (hierarchyA[i] !== hierarchyB[i]) {
        return hierarchyA[i].localeCompare(hierarchyB[i]);
      }
    }

    // If one path is shorter and all previous elements were equal,
    // shorter path should come first
    return hierarchyA.length - hierarchyB.length;
  };
  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: "Name",
      width: isMobile ? 120 : 300,
      // pinned: isMobile ? null : "left",
      sort: "asc",
      sortable: true,
      pinned: isMobile ? null : "left",
      cellStyle: tableCellStyle,
      valueGetter: (params) =>
        params.data.orgHierarchy[params.data.orgHierarchy.length - 1],
      cellRenderer: "agGroupCellRenderer",
      menuTabs: ["filterMenuTab"],

      filter: "agTextColumnFilter",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: memo(TaskNameRender),
      },
      comparator: comparator,
    };
  }, []);
  const [view, setView] = useState("list");
  return (
    <>
      {modalOpen?.data && modalOpen?.type === "Duplicate" && (
        <SideBarAddTaskPanel
          taskData={modalOpen.data}
          projectIdData={modalOpen.data.projectId}
          isOpen={modalOpen.state}
          onClose={() => {
            setModelOpen({
              state: false,
              type: "Duplicate",
              data: null,
            });
          }}
          onCreateProject={() => {
            setModelOpen({
              state: false,
              type: "Duplicate",
              data: null,
            });
          }}
        />
      )}

      {modalOpen?.data && modalOpen?.type === "Delete" && (
        <DeleteTaskModal
          taskIdData={modalOpen?.data?.id}
          projectId={modalOpen.data.projectId}
          taskNameData={modalOpen?.data.name}
          isOpen={modalOpen.state}
          onClose={() => {
            setModelOpen({
              state: false,
              type: "Delete",
              data: null,
            });
          }}
        />
      )}
      {modalOpen?.data && modalOpen?.type === "Reminder" && (
        <MailReminderModal
          taskIdData={modalOpen?.data?.id}
          projectId={modalOpen.data.projectId}
          taskNameData={modalOpen?.data.name}
          isOpen={modalOpen.state}
          onClose={() => {
            setModelOpen({
              state: false,
              type: "Reminder",
              data: null,
            });
          }}
        />
      )}
      {modalOpen?.data && modalOpen?.type === "Link Company" && (
        <LinkCompanyModal
          taskIdData={modalOpen?.data?.id}
          projectId={modalOpen.data.projectId}
          companyNam={modalOpen?.data.companyName ?? ""}
          isOpen={modalOpen.state}
          onClose={() => {
            setModelOpen({
              state: false,
              type: "Reminder",
              data: null,
            });
          }}
        />
      )}

      {isUpdateProjectDetails.state && (
        <SideBarPanel
          tags={[]}
          realTasks={isUpdateProjectDetails.task}
          open={isUpdateProjectDetails.state ?? false}
          onClose={() => {
            setUpdateProjectDetails({ state: false, task: undefined });
          }}
          isAdmin={true}
        />
      )}
      {(project?.id ?? "") && isAdmin && (
        <SideBarAddTaskPanel
          isOpen={isCreateTask}
          onClose={() => {
            setCreateTask(false);
          }}
          onCreateProject={() => {
            setCreateTask(false);
          }}
          projectIdData={project.id ?? ""}
        />
      )}
      <div className="px-8 py-0">
        <HStack className={`justify-between items-baseline`}>
          {project ? (
            <div></div>
          ) : (
            <VStack className="sm:flex hidden justify-end -ml-8">
              {/* {view === "list" ? ( */}
              <MultiSelectFilterTabWithoutAll
                tabs={projectType}
                onChange={handleTypeChange}
              />
              <div></div>
              {/* ) : (
                <div className="h-8"></div>
              )} */}
            </VStack>
          )}
          {/* {project && ( */}
          <VStack className="sm:flex hidden justify-end -mr-8">
            <div></div>
            {/* {view === "list" ? ( */}
            <MultiSelectFilterTab
              key={selectedProjectType}
              selected={selectedFilters}
              tabs={statuses.map((ele: any) => ele.name)}
              onChange={handleFilterChange}
            />
            {/* ) : (
              <div className="h-8"></div>
            )} */}
          </VStack>
          {/* )} */}
        </HStack>
      </div>

      <>
        <HStack className="flex-col  items-start justify-start py-2 bg-white border rounded-md rounded-tr-none lg:flex-row lg:justify-between lg:items-center border-borderColor shadow-box w-full">
          <HStack className="pl-8 gap-4 items-center">
            <SearchInput
              placeholder={`Search Task`}
              onChange={(e: any) => {
                if (view === "list") {
                  gridApi.current.api.setQuickFilter(e.target.value);
                  setSearchTerm(e.target.value);
                } else {
                  setSearchTerm(e.target.value);
                }
              }}
            />
            {isAdmin && isSubscribed && (
              <UserSelectComponent users={users} onChange={handleUserChange} />
            )}
            {/* {!project && (
                <MultiSelectFilterDropdown
                  tabs={projectType}
                  onChange={handleTypeChange}
                />
              )} */}
            {view === "list" && (
              <div className="sm:block">
                <CloudSetting
                  tableId={tableId}
                  isLoading={isSaving}
                  filterCondition={filterCondition}
                  setFilterCondition={setFilterCondition}
                />
              </div>
            )}
          </HStack>
          <HStack className="sm:items-center sm:flex-row flex-col items-start justify-between gap-4 px-8 py-2 lg:w-auto lg:justify-between w-full">
            <HStack className="gap-4">
              {/* 
              grouping by project removed
              {!project && isSubscribed && view === "list" && (
                <div className="flex flex-row items-center cursor-pointer gap-2">
                  <input
                    type="checkbox"
                    aria-describedby="groupproject"
                    name="groupproject"
                    id="groupproject"
                    className="cursor-pointer"
                    checked={includedProjectName}
                    onChange={() => {
                      handleClickForTracking(
                        AnalyticsConstant.table_group_project_button
                      );
                      setIncludeProjectName(!includedProjectName);
                    }}
                  />
                  <label
                    htmlFor="groupproject"
                    className="cursor-pointer text-sm text-gray-700"
                  >
                    Group Project
                  </label>
                </div>
              )} 
               */}
              <TagFilter
                data={
                  tasks.filter(
                    (ele) => ele.projectType === selectedProjectType,
                  ) ?? []
                }
                onSelect={setSelectedTags}
              />
              {view === "list" && (
                <IconCTAButton
                  value={"View"}
                  onClick={() => {
                    handleClickForTracking(
                      project
                        ? AnalyticsConstant.project_table_column_tab
                        : AnalyticsConstant.table_column_tab,
                    );
                    openToolPanel("columns");
                  }}
                  selected={isColumnOpen}
                  iconName={"basil:settings-adjust-solid"}
                  iconClassName="block"
                  textClassName="sm:block hidden"
                  className={`sm:px-4 px-1 font-medium items-center flex flex-row ${
                    isColumnOpen ? "text-orange-501" : "text-gray-400"
                  }`}
                />
              )}

              {isAdmin && project && (
                <PrimaryCTAButton
                  onClick={() => {
                    handleClickForTracking(
                      project
                        ? AnalyticsConstant.project_table_create_task_button
                        : AnalyticsConstant.project_table_create_task_button,
                    );

                    setCreateTask(true);
                  }}
                  buttonSize={ButtonSize.MEDIUM}
                >
                  <span className="hidden lg:block">Add Task</span>
                  <BookmarkPlus className="w-4 h-4 ml-2" />
                </PrimaryCTAButton>
              )}
            </HStack>
            <div className="flex bg-gray-100 rounded-lg p-1">
              {["list", "calender", "kanban"].map((mode) => (
                <button
                  key={mode}
                  onClick={() => setView(mode)}
                  className={`px-3 py-1 text-sm rounded-md transition-colors
                    ${
                      view === mode
                        ? "bg-blue-500 text-white font-medium shadow-sm"
                        : "text-gray-600 hover:text-gray-800"
                    }`}
                >
                  {mode.charAt(0).toUpperCase() + mode.slice(1)}
                </button>
              ))}
            </div>
          </HStack>
        </HStack>

        {view === "list" ? (
          <HStack className="justify-between relative w-full">
            <Box
              style={{
                height: setTableHeight(),
              }}
              className="w-full max-h-full min-h-96 overflow-x-auto ag-theme-material h-full "
            >
              <AgGridReact
                sideBar={agConfigDashboardSideBar}
                onGridReady={(params) => {
                  gridApi.current = params;
                }}
                components={componentsRegistery}
                onColumnMoved={uploadColumn}
                alwaysShowHorizontalScroll
                groupDefaultExpanded={-1}
                getDataPath={getDataPath}
                treeData
                autoGroupColumnDef={autoGroupColumnDef}
                alwaysMultiSort
                animateRows={true}
                defaultColDef={defaultColDef}
                onFilterChanged={onAgGridFilterChanged}
                onColumnEverythingChanged={uploadColumn}
                rowData={rowData}
                onCellClicked={handleCellClick}
                columnDefs={columnDefs}
                pagination={false}
                suppressRowTransform={true}
                suppressCopyRowsToClipboard={true}
                suppressCopySingleCellRanges={true}
                suppressCellFocus={true}
                suppressMenuHide={false}
                onColumnResized={uploadColumn}
                tooltipShowDelay={1000}
                tooltipInteraction={true}
                rowClass={"border-t border-dashed"}
                gridOptions={gridOptions}
                overlayNoRowsTemplate={
                  ' <div style="text-align: center; padding: 40px; "> <div style="display: inline-block;"><div style="font-size: 18px; color: #555; margin-bottom: 8px;">Table is empty</div><div style="font-size: 14px; color: #777;">No data available at the moment</div></div></td>'
                }
                getRowStyle={(params) => {
                  if (params.data.isRead) {
                    return { background: "#F2F6FB" };
                  } else {
                    return { background: "#ffffff" };
                  }
                }}
              ></AgGridReact>
            </Box>
          </HStack>
        ) : view === "kanban" ? (
          <KanbanBoard
            allTasks={sortedTask ?? ""}
            onCardClick={setUpdateProjectDetails}
          />
        ) : (
          // <KanbanBoard projectId={project?.id ?? ""} />
          <CalendarPage allTasks={sortedTask ?? ""} />
        )}
      </>
    </>
  );
}
